@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans.eot');
    src: url('OpenSans.eot?#iefix') format('embedded-opentype'),
        url('OpenSans.woff2') format('woff2'),
        url('OpenSans.woff') format('woff'),
        url('OpenSans.ttf') format('truetype'),
        url('OpenSans.svg#OpenSans') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Light.eot');
    src: url('OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-Light.woff2') format('woff2'),
        url('OpenSans-Light.woff') format('woff'),
        url('OpenSans-Light.ttf') format('truetype'),
        url('OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Extrabold.eot');
    src: url('OpenSans-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-Extrabold.woff2') format('woff2'),
        url('OpenSans-Extrabold.woff') format('woff'),
        url('OpenSans-Extrabold.ttf') format('truetype'),
        url('OpenSans-Extrabold.svg#OpenSans-Extrabold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-ExtraboldItalic.eot');
    src: url('OpenSans-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-ExtraboldItalic.woff2') format('woff2'),
        url('OpenSans-ExtraboldItalic.woff') format('woff'),
        url('OpenSans-ExtraboldItalic.ttf') format('truetype'),
        url('OpenSans-ExtraboldItalic.svg#OpenSans-ExtraboldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Semibold.eot');
    src: url('OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-Semibold.woff2') format('woff2'),
        url('OpenSans-Semibold.woff') format('woff'),
        url('OpenSans-Semibold.ttf') format('truetype'),
        url('OpenSans-Semibold.svg#OpenSans-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSansLight-Italic.eot');
    src: url('OpenSansLight-Italic.eot?#iefix') format('embedded-opentype'),
        url('OpenSansLight-Italic.woff2') format('woff2'),
        url('OpenSansLight-Italic.woff') format('woff'),
        url('OpenSansLight-Italic.ttf') format('truetype'),
        url('OpenSansLight-Italic.svg#OpenSansLight-Italic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-BoldItalic.eot');
    src: url('OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-BoldItalic.woff2') format('woff2'),
        url('OpenSans-BoldItalic.woff') format('woff'),
        url('OpenSans-BoldItalic.ttf') format('truetype'),
        url('OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Italic.eot');
    src: url('OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-Italic.woff2') format('woff2'),
        url('OpenSans-Italic.woff') format('woff'),
        url('OpenSans-Italic.ttf') format('truetype'),
        url('OpenSans-Italic.svg#OpenSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Bold.eot');
    src: url('OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-Bold.woff2') format('woff2'),
        url('OpenSans-Bold.woff') format('woff'),
        url('OpenSans-Bold.ttf') format('truetype'),
        url('OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-SemiboldItalic.eot');
    src: url('OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-SemiboldItalic.woff2') format('woff2'),
        url('OpenSans-SemiboldItalic.woff') format('woff'),
        url('OpenSans-SemiboldItalic.ttf') format('truetype'),
        url('OpenSans-SemiboldItalic.svg#OpenSans-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

